<template>
	<v-layout class="phone-text-field" :style="{ marginBottom: hideBottomMargin ? '0px' : '12px !important' }">
		<v-flex class="max-width-80px">
			<v-select outlined v-model="countryCode" :items="countryList" hide-details :disabled="disabled"
				:loading="loading" item-text="name" item-value="iso2" item-color="cyan" return-object
				v-on:change="updateActiveCountry">
				<template v-slot:selection>
					<div :class="activeCountry.iso2.toLowerCase()" class="vti__flag mr-2" />
				</template>
				<template v-slot:item="data">
					<span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
					<span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
				</template>
			</v-select>
		</v-flex>
		<v-flex>
			<v-text-field :id="id" v-model.number="phone" ref="phoneRef" outlined :rules="rules" hide-details
				:disabled="disabled" :loading="loading" :placeholder="placeholder ? placeholder : 'Phone Number'"
				:prefix="`+${this.countryCode.dialCode}-`" @keypress="inputValue($event, phone)" @paste="onPaste"
				@keyup="debounceFunc(validateNumberMala, phone)">
				<!-- @input="debounceFunc(validateNumberMala, phone)" -->
				<!-- @blur="
					$emit('input', phone);
					$emit('change', phone);
					$emit('blur', phone);
				"
				@focus="
					$emit('input', phone);
					$emit('change', phone);
				" -->
				<!-- v-on:keyup="
					$emit('input', phone);
					$emit('change', phone);
					validateNumber(phone);
				" -->
				<!-- v-on:change="
					$emit('input', phone);
					$emit('change', phone);
					validateNumberMala(phone, $event);
				" -->
				<!-- @blur="$emit('blur', phone)"-->
			</v-text-field>
			<template v-if="invalidNumber">
				<span style="color: red; font-weight: 600">{{ invalidNumber }}</span>
			</template>
		</v-flex>
	</v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
//import SelectInput from "@/view/components/SelectInput.vue";
//import { trimStart } from "lodash";

import MainMixin from "@/core/mixins/main.mixin.js";
import allCountries from "@/core/plugins/country-list";
import { find, split, replace } from "lodash";
import { QUERY } from "@/core/services/store/request.module";
import { toString } from "lodash";
export default {
	name: "text-phone-input",
	mixins: [MainMixin],
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: "phone",
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "Phone Number",
		},
		hideBottomMargin: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			phone: null,
			validateNumberCheck: null,
			invalidNumber: null,
			countryCode: {
				areaCodes: null,
				dialCode: "65",
				iso2: "SG",
				name: "Singapore",
				priority: 0,
			},
			activeCountry: {
				areaCodes: null,
				dialCode: "65",
				iso2: "SG",
				name: "Singapore",
				priority: 0,
			},
			phoneMask: "+65-########",
			phonePrefix: "+65",
			countryList: allCountries,
			debounceTimer: null,
		};
	},
	components: {
		//SelectInput,
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(newv, old) {
				if (newv != old) {
					this.setValues();
				}
			},
		},
	},
	methods: {
		focus() {
			this.$refs.phoneRef.focus();
		},
		onPaste(evt) {
			/* Prevents string from being pasted */
			let text = evt.clipboardData.getData("Text");
			let newText = text.replaceAll(" ", "");
			let isValid = +newText ? +newText : false;
			console.log({ isValid });
			if (!isValid) {
				evt.preventDefault();
			}
			else {
				let length = this.countryCode.dialCode == 65 ? 8 : 12;
				this.phone = newText.substring(0, length);
				this.validateNumberMala(newText.substring(0, length))
			}
		},
		inputValue($event, value) {
			let val = $event;
			let s = toString(value);
			let charCode = val.charCode || val.which;
			let pp = this.countryCode.dialCode == 65 ? s.length < 8 : s.length < 12;
			if (charCode >= 48 && charCode <= 57 && pp) {
				return false;
			} else {
				$event.preventDefault();
			}
		},
		/* validateNumber(number) {
			if (number && number.length) {
				let maxLimit = Number(number.length) - 4;

				if (number && this.countryCode.phone_character_limit == maxLimit) {
					const _this = this;
					let apiKey = process.env.VUE_APP_MOBILE_KEY;
					_this.$store
						.dispatch(QUERY, {
							url: "https://micro.businessthrust.com/api/check-mobile",
							data: {
								mobile: number,
								apikey: apiKey,
							},
						})
						.then((data) => {
							this.validateNumberCheck = data.result;
							if (
								this.validateNumberCheck &&
								!this.validateNumberCheck.valid &&
								this.validateNumberCheck.line_type == "N/A"
							) {
								// (this.phone = null),
								this.invalidNumber = "Invalid Number";
							} else {
								this.invalidNumber = null;
							}
						})
						.catch((error) => {
							_this.logError(error);
						});
				}
			}
		}, */
		debounceFunc(cb, param = null) {
			let timeSpan /* ms */ = 800;
			clearTimeout(this.debounceTimer);
			this.debounceTimer = setTimeout(() => cb(param), timeSpan);
		},
		checkPhoneLengthAccToCountry() {
			let _phone = this.phone.toString();
			if (this.countryCode.dialCode == "65" && _phone.length == 8) {
				return true;
			} else if (_phone.length > 7) {
				return true;
			} else {
				return false;
			}
		},
		validateNumberMala(number) {
			if (number) {
				if (this.checkPhoneLengthAccToCountry()) {
					const _this = this;
					this.$emit("loading", true);
					let apiKey = process.env.VUE_APP_MOBILE_KEY;
					let finalphone = `+${this.countryCode.dialCode}-${number}`;
					_this.$store
						.dispatch(QUERY, {
							url: "https://micro.businessthrust.com/api/check-mobile",
							data: {
								mobile: finalphone,
								apikey: apiKey,
							},
						})
						.then((data) => {
							this.validateNumberCheck = data.result;
							if (
								this.validateNumberCheck &&
								!this.validateNumberCheck.valid &&
								this.validateNumberCheck.line_type == "N/A"
							) {
								// this.phone = null
								this.invalidNumber = "Invalid Number";
								this.$emit("validity", false);
							} else {
								this.$emit("validity", true);
								this.$emit("input", `+${this.countryCode.dialCode}-${number}`);
								this.invalidNumber = null;
							}
						})
						.catch((error) => {
							_this.logError(error);
						})
						.finally(() => {
							// _this.loading = false;
							this.$emit("loading", false);
						});
				}
			} else {
				this.$emit("validity", true);
				this.$emit("clear", true);
				this.invalidNumber = null;
			}
		},
		setValues() {
			const _value = replace(this.value, `+`, "");
			const phoneCode = split(_value, "-", 1);

			if (phoneCode && phoneCode[0]) {
				const countryCode = find(this.countryList, {
					dialCode: phoneCode[0],
				});

				if (countryCode) {
					this.countryCode = countryCode;
					this.activeCountry = countryCode;
				}
			}

			const value = replace(this.value, `+${phoneCode[0]}-`, "");
			this.phone = value ? value : null;

			/*
			No Longer need of Phone Mask

			this.$nextTick(() => {
				if (phoneCode[0]) {
					if (phoneCode[0] == "65") {
						this.phoneMask = `+${phoneCode[0]}-########`;
					} else {
						this.phoneMask = `+${phoneCode[0]}-##########`;
					}
				}
			}); */
		},
		updateActiveCountry() {
			/* const { dialCode } = this.countryCode; */
			this.$emit("clear", true);
			this.phone = "";
			this.activeCountry = this.countryCode;
			this.invalidNumber = null;

			/* if (dialCode) {
				if (dialCode == "65") {
					this.phoneMask = `+${dialCode}-########`;
				} else {
					this.phoneMask = `+${dialCode}-##########`;
				}
			} */
		},
	},
	computed: {
		rules() {
			const { dialCode } = this.countryCode;
			const maxLimit = dialCode == "65" ? 8 : 15;
			const minLimit = 8;

			const rule = [
				this.vrules.minLength(this.phone, "phone number", minLimit),
				this.vrules.maxLength(this.phone, "phone number", maxLimit),
			];

			if (this.required) {
				rule.push(this.vrules.required(this.phone, "phone number"));
			}
			return rule;
		},
	},
	mounted() {
		// this.setValues();
	},
};
</script>
